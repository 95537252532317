.swiper2 {
  max-height: 800px;
  width: 100%;
  cursor: pointer;
}
.SwiperSlide2 {
  width: 100%;
  height: 100%;
}
.SwiperSlide2 img {
  width: 100%;
  height: 100%;
}
.swiper {
  width: 100%;
  max-height: 130px;
  cursor: pointer;
  padding-top: 10px;
}
.swiper img {
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 40px;
  padding-left: 20px;
}
.headingCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.headingCont h2 {
  align-self: flex-start;
  text-align: start;
  font-size: 24px;
  text-transform: none;
  font-style: normal;
}
.headingCont h5 {
  align-self: flex-start;
  text-align: start;
  font-size: 25px;
  font-weight: 600;
  color: #8d8d8d;
}
.priceRow {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  gap: 20px;
}
.priceRow h3 {
  text-decoration: line-through;
  text-decoration-thickness: 2.8px;
  text-decoration-color: #717171;
  font-size: 25px;
  font-weight: 600;
  color: #8d8d8d;
}
.customCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
}
.custom1 {
  width: 30%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 25px;
}
.custom2 {
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 20px;
}
.custom2 h4 {
  font-style: normal;
}
.custom2 h5 {
  color: #8d8d8d;
}
.sizes {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
}
.sizes span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  width: 40px;
  font-size: 15px;
  font-family: "Montserrat", "san's-serif";
  cursor: pointer;
}
.sizeActive {
  background-color: #8d8d8d;
  color: white;
}
.buttonCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.input {
  width: 110px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid rgb(223, 223, 223);
  padding-left: 10px;
  padding-right: 10px;
}
.input img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.input span {
  padding: 10px 20px 10px 20px;
  background-color: rgb(240, 240, 240);
}
.addToCart {
  border-radius: 0;
  background-color: black;
  width: 70%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 0px 10px 0px;
  border: 2px solid black;
}
.addToCart:hover {
  background-color: rgb(50, 50, 50);
  border: 2px solid rgb(50, 50, 50);
}
.addToCart:disabled {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.632);
  border: none;
}
.infoCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
.sizeChart {
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 400px;
}
@media (max-width: 1200px) {
  .custom2 {
    gap: 22px;
  }
}
@media (max-width: 992px) {
  .box {
    padding-left: 0px;
  }
  .sizeChart {
    min-width: auto;
    min-height: 400px;
  }
}
@media (max-width: 480px) {
  .headingCont {
    gap: 10px;
  }
  .headingCont h2 {
    font-size: 20px;
  }
  .headingCont h5 {
    font-size: 21px;
  }
  .priceRow h3 {
    font-size: 21px;
  }
  .input {
    width: 80px;
    height: 90%;
  }
  .input img {
    width: 7px;
    height: 7px;
  }
  .input span {
    padding: 8px 10px 7px 10px;
  }
  .addToCart {
    width: 60%;
  }
  .custom2 {
    padding-left: 11vw;
    gap: 20px;
  }
  .sizes span {
    font-size: 12px;
  }
}

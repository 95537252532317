.cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.boxCont {
  width: 100%;
  height: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.boxCont h5 {
  text-align: start;
  align-self: flex-start;
  padding-top: 15px;
  font-weight: 500;
}
.boxCont p {
  text-align: start;
  align-self: flex-start;
}
.boxCont:hover .box {
  box-shadow: 1px 0px 20px rgba(54, 54, 54, 0.26);
}
.box {
  width: 100%;
  height: 100%;
  min-height: 380px;
  max-height: 450px;
}
.iconCont {
  padding: 0px 20px 0px 20px;
  align-self: flex-end;
}
.iconCont span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.425);
}
.iconCont img {
  width: 70%;
  height: 70%;
}
.sizeCont {
  background-color: rgba(255, 255, 255, 0.507);
  padding: 16px 20px 16px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.sizeCont h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  color: #141414;
  align-self: center;
}
.sizes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.sizes h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.842);
}
.foot {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: row;
  gap: 15px;
}
.foot h3 {
  color: #717171;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: #717171;
}
@media (max-width: 1200px) {
  .box {
    min-height: 265px;
  }
  .sizeCont h6 {
    font-size: 11px;
  }
  .sizes h6 {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 992px) {
  .boxCont {
    max-width: 320px;
  }
  .box {
    min-height: 305px;
    max-height: 375px;
  }
  .productPage {
    min-height: 305px;
    max-height: 375px;
  }
}

.swiper_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.swiperSlide {
  min-height: 480px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.row1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.headingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: auto;
}
.headingBox h2 {
  width: 100%;
  align-self: flex-start;
  text-align: start;
}
.headingBox a {
  width: 100%;
  align-self: flex-end;
  text-align: end;
  text-decoration: none;
}
.headingBox h4 {
  width: 100%;
  align-self: flex-end;
  text-align: end;
}
.imgBox {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  gap: 50px;
}
.imgBox img {
  cursor: pointer;
  max-width: 50px;
}
@media (max-width: 1200px) {
  .swiperSlide {
    min-height: 380px;
  }
}
@media (max-width: 480px) {
  .imgBox {
    gap: 30px;
  }
  .imgBox img {
    width: 20px;
  }
}

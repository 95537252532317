.root h1{
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    color: #000000;
    letter-spacing: 2px;
    font-size: 58px;
    text-align: left;
}
.root h3{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #000000;
    letter-spacing: 2px;
    text-align: left;
}
.root p{
    color: #000000;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.6px;
    line-height: 2;
    font-size: 14px;
    text-align: left;
}
.root a{
    color: #000000;
    font-family: "Poppins", sans-serif;
}
.root a:hover{
    color: #670c83;
}
@media (max-width: 768px) {
    .root h1{ 
        font-size: 36px;
       }
      .root p{
          font-size: 14px;
      } 
}
@media (max-width: 468px) {
    .root h1{
        padding-left: 2vh;
        padding-right: 2vh;
        font-size: 26px;
       }
       .root h3{
        padding-left: 2vh;
        padding-right: 2vh;
        font-size: 22px;
       }
    .root p{
        padding-left: 2vh;
        padding-right: 2vh;
        font-size: 11px;
       } 
}
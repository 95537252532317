.headerDesktop {
  width: 100%;
  padding: 30px 0px 30px 0px;
  border-bottom: 1px solid rgb(225, 225, 225);
}
.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.search {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid rgb(184, 184, 184);
}
.search input {
  width: 75%;
  background-color: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0;
}
.search input:focus {
  outline: none;
}
.search input::placeholder {
  font-size: 13px;
  font-family: "Montserrat", "sans-serif";
  color: #000000;
}
.search img {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.logoCont {
  width: 14%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.logoCont img {
  width: 72%;
  min-width: 160px;
  max-height: 150px;
  align-self: flex-start;
}
.logoCont a {
  width: 100%;
  align-self: flex-start;
}
.contentCont {
  width: 80%;
}
.contentImg {
  width: 75%;
  max-height: 110px;
}
.contentRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listCont {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}
.listCont a {
  text-decoration: none;
  color: #000000;
}
.link {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", "sans-serif";
  text-transform: uppercase;
  margin: 0;
  color: #000000;
  text-decoration: none;
}
.activeLink {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", "sans-serif";
  text-transform: uppercase;
  margin: 0;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
}
.link:hover {
  color: #000;
}
.activeLink:hover {
  color: #000;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.box a {
  text-align: start;
  align-self: flex-start;
}
.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  font-family: "Montserrat", "sans-serif";
  text-transform: uppercase;
  margin: 0;
  color: #000000;
  text-decoration: none;
  gap: 4px;
}
.icon:hover {
  color: #000000;
}
.icon:hover img {
  transform: scale(1.1);
}
.icon img {
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
}
.infoCont {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 17px;
}
.username {
  text-align: center;
  color: #000000;
  text-decoration: none;
}
.username a {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", "sans-serif";
  margin: 0;
  color: #000000;
}
.username a:hover {
  background-color: transparent;
  text-decoration: none;
  color: #5b5b5b;
}
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}
.dropdownContentCont {
  height: auto;
  display: none;
  position: absolute;
  min-width: 180px;
  transition: 0.3s ease;
}
.dropdownContent {
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  margin-top: 18px;
  border-radius: 5px;
  margin-top: 15px;
}
.dropdown:hover .dropdownContentCont {
  display: block;
  animation: fadeInFromNone 0.4s ease-out;
}
.dropdownContentLink h6 {
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  color: black;
}
.dropdownContentActiveLink h6 {
  font-weight: 900;
}
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  .logoCont img {
    min-width: 90%;
  }
  .iconCont {
    width: 15%;
  }
  .listCont {
    justify-content: center;
    padding-right: 5px;
    gap: 25px;
  }
  .link {
    font-size: 11px;
  }
  .activeLink {
    font-size: 11px;
  }
  .username a {
    font-size: 12px;
  }
}

/* MOBILE NAVIGATION --> */

.mobSearch {
  display: none;
}
.mobHeader {
  display: none;
}
.mobNav {
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid rgb(225, 225, 225);
}
.mobIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 2px;
  color: #000000;
}
.mobIcon:hover {
  color: #000000;
}
.mobIcon:hover img {
  transform: scale(1.1);
}
.mobIcon img {
  width: 18px;
  height: 17px;
  transition: 0.2s ease-in-out;
}
.mobToggler {
  font-size: 22px;
  border: 0.1px solid;
  border-color: rgb(255, 255, 255);
  background-color: #fff;
  color: #383838;
  padding-right: 0px;
}
.mobToggler:focus {
  box-shadow: none;
}

@media (max-width: 992px) {
  .headerDesktop {
    display: none;
  }

  /* MOBILE NAVIGATION --> */

  .mobHeader {
    display: block;
  }
  .mobSearch {
    display: block;
    border-bottom: 1px solid rgb(225, 225, 225);
    padding: 35px 25px 35px 25px;
    cursor: pointer;
  }
  .searchCont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .search {
    width: 70%;
    height: 20px;
    border-bottom: none;
  }
  .search input {
    width: 80%;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    margin: 0;
  }
  .search input::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
  .search img {
    height: 24px;
    width: 24px;
  }
  .close {
    width: 25px;
    height: 25px;
  }
  .link {
    align-self: flex-start;
    text-align: start;
    font-size: 12px;
  }
  .activeLink {
    align-self: flex-start;
    text-align: start;
    font-size: 12px;
  }
  .mobNav a {
    border: none;
    box-shadow: none;
  }
  .mobNav div {
    border: none;
    box-shadow: none;
  }
  .username a {
    align-self: flex-start;
    text-align: start;
    font-size: 11px;
    margin-right: 11px;
    color: #000;
    padding: 0;
  }
  .username a:focus {
    color: black;
  }
}
@media (max-width: 767px) {
  .logoCont {
    width: 20%;
  }
}
@media (max-width: 480px) {
  .logoCont {
    width: 24%;
  }
}

.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.swiper_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.swiperSlide {
  min-height: 480px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.swiperSlide h5 {
  padding-top: 15px;
}
.box {
  padding: 40px;
  width: 100%;
  height: 80%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  min-height: 370px;
  gap: 15px;
}
.box a {
  align-self: center;
}
@media (max-width: 1200px) {
  .swiperSlide {
    min-height: 380px;
  }
  .box {
    max-width: 320px;
  }
}

.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
}
.heading_capital {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #141414;
}
.heading_capital span {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #c08a7c;
}
.h1_main {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #141414;
}
.h2_main {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #141414;
}
.h4_main {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #141414;
}
.h5_main {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #141414;
}
.para_main {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: #8d8d8d;
  margin: 0;
}
.button_main {
  background-color: #000000;
  text-align: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  color: white;
  padding: 10px 40px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  transform: skew(-21deg);
  transition: 0.2s ease-in-out;
}
.button_main span {
  display: inline-block;
  transform: skew(21deg);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 3px;
  color: white;
}
.button_main:hover {
  background-color: #ffffff9f;
  backdrop-filter: blur(10px);
}
.button_main:hover span {
  color: rgb(0, 0, 0);
}
.button_white {
  background-color: #ffffff9f;
  backdrop-filter: blur(10px);
}
.button_white span {
  color: rgb(0, 0, 0);
}
.button_white:hover {
  background-color: #000000;
  color: white;
}
.button_white:hover span {
  color: white;
}
@media (max-width: 1200px) {
  .heading_capital {
    font-size: 52px;
  }
  .heading_capital span {
    font-size: 52px;
  }
  .h4_main {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .heading_capital {
    font-size: 32px;
  }
  .heading_capital span {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
  .heading_capital {
    font-size: 26px;
  }
  .heading_capital span {
    font-size: 26px;
  }
  .h1_main {
    font-size: 20px;
  }
  .h2_main {
    font-size: 16px;
  }
  .h4_main {
    font-size: 11px;
  }
  .h5_main {
    font-size: 13px;
  }
  .para_main {
    font-size: 12px;
  }
  .button_main {
    padding: 7px 25px;
  }
  .button_main span {
    font-size: 9px;
    letter-spacing: 2px;
  }
}
.admin-screens-mg-top {
  margin-top: 15vh;
}
.user-screens-mg-top {
  margin-top: 15vh;
}

.formScreenNoMargin {
  padding-top: 0vh;
}
.formScreen h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
  text-transform: uppercase;
}
.formScreen a {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #46045a;
}
.formScreen a:hover {
  color: #670c83;
}
.formScreen a:focus {
  color: #670c83;
}
.formScreen p {
  font-family: "Poppins", sans-serif;
  color: #585958;
  text-align: center;
}
.form input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #002c53;
  border-radius: 0;
}
.form input[type="text"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="name"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #002c53;
  border-radius: 0;
}
.form input[type="name"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #002c53;
  border-radius: 0;
}
.form input[type="email"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #002c53;
  border-radius: 0;
}
.form input[type="password"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form textarea {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #002c53;
  border-radius: 0;
}
.form textarea:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
  border: 2px solid #002c53;
}
.form label {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #003869;
}
.formCheck label {
  font-family: "Poppins", sans-serif;
  color: #585958;
  font-size: 15px;
}
.phoneInput p {
  font-size: 15px;
  text-align: left;
  color: #585958;
}
.securityText p {
  font-size: 15px;
  text-align: left;
  color: #585958;
}
.form [placeholder] {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: #002c53;
  line-height: 1.5;
}
.form button {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border: 1px solid #002c53;
  background-color: #002c53;
  color: white;
  border-radius: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}
.form button:hover {
  border: 1px solid #003869;
  background-color: #003869;
}
.form button:disabled {
  border: 1px solid #002c5365;
  background-color: #002c5365;
  z-index: 999;
}
.googleButton {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}
.orText h1 {
  display: flex;
  flex-direction: row;
  font-size: 20px;
}
.orText h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.orText h1:before {
  margin-right: 10px;
}
.orText h1:after {
  margin-left: 10px;
}
.reText h1 {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-top: 0.1vh;
  margin-bottom: -0.1vh;
}
.reText h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.reText h1:before {
  margin-right: 10px;
}
.reText h1:after {
  margin-left: 10px;
}

@media (max-width: 480px) {
  .form [placeholder] {
    font-size: 13px;
  }
  .form label {
    font-size: 15px;
  }
  .securityText p {
    font-size: 13px;
    text-align: left;
    color: #585958;
  }
  .phoneInput p {
    font-size: 13px;
  }
  .formCheck label {
    font-family: "Poppins", sans-serif;
    color: #585958;
    font-size: 13px;
  }
}
.orderingScreensNoMargin {
  margin-top: 0vh;
}
.img-back {
  width: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}
.orderingScreens h1 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
  text-align: center;
}
.orderingScreens h2 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h3 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h5 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h6 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.5vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens span {
  font-family: "DM Sans", sans-serif;
  margin-bottom: 1.5vh;
  font-size: 18px;
  font-weight: 900;
  color: #670c83;
}
.orderingScreens a {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #4d2c57;
}
.orderingScreens a:hover {
  color: #670c83;
}
.orderingScreens a:focus {
  color: #670c83;
}
.orderingScreens p {
  font-family: "Poppins", sans-serif;
  color: #585958;
}
.orderingScreens button {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border: 1px solid #002c53;
  background-color: #002c53;
  color: white;
  border-radius: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}
.orderingScreens button:hover {
  border: 1px solid #004078;
  background-color: #004078;
}
.orderingScreens button:disabled {
  border: 1px solid #002c53b3;
  background-color: #002c53b3;
  z-index: 999;
}
.orderingScreens .goBack {
  width: 10%;
}
.orderingScreens select {
  width: 65px;
  height: 35px;
  border: 1px solid;
  border-color: #002c53;
  border-radius: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #002c53;
  font-weight: 900;
  padding: 5px;
}
.orderingScreens select:focus {
  border-color: #002c53;
}
.orderingScreens .trashIcon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.orderId span {
  color: #2b2b2b;
  font-size: 26px;
}
.emptyCartLink a {
  font-size: 20px;
}
.user-screens h1 {
  text-align: left;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.user-screens th {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #000000;
  letter-spacing: 2px;
}
.user-screens td {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #000000;
  letter-spacing: 2px;
}
@media (max-width: 768px) {
  .orderingScreens .trashIcon {
    height: 42px;
    width: 42px;
  }
  .dek-table {
    display: none;
  }
  .mob-table {
    display: block;
  }
}
@media (min-width: 768px) {
  .dek-table {
    display: block;
  }
  .mob-table {
    display: none;
  }
}
@media (max-width: 480px) {
  .orderingScreens .orderId {
    font-size: 17px;
    text-align: center;
  }
  .orderId span {
    font-size: 13px;
  }
  .img-back {
    width: 25px;
  }
  .user-screens td {
    font-size: 11px;
    letter-spacing: 0.6px;
  }
  .user-screens a {
    font-size: 10px;
  }
}

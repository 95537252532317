#progress-bar {
  display: table;
  text-align: center;
  padding: 15px 15px 0;
  table-layout: fixed;
  width: 100%;
  counter-reset: step;
}
#progress-bar li {
  list-style-type: none;
  display: table-cell;
  width: 20%;
  font-size: 16px;
  position: relative;
  text-align: center;
}
#progress-bar li:before {
  width: 50px;
  height: 50px;
  color: #212121;
  content: counter(step);
  counter-increment: step;
  line-height: 50px;
  font-size: 18px;
  border: 1px solid #efefef;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #fff;
}
#progress-bar li:after {
  width: 100%;
  height: 8px;
  content: "";
  position: absolute;
  background-color: #fff;
  top: 25px;
  left: -50%;
  z-index: -1;
}
#progress-bar li:first-child:after {
  content: none;
}
#progress-bar li.step-done {
  color: #003869;
}
#progress-bar li.step-done:before {
  border-color: #003869;
  background-color: #003869;
  color: #fff;
  content: "";
  font-family: "FontAwesome";
}
#progress-bar li.step-done + li:after {
  background-color: #003869;
}
#progress-bar li.step-active {
  color: #003869;
}
#progress-bar li.step-active:before {
  border-color: #003869;
  color: #003869;
  font-weight: 700;
}

.pg-bar-link {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #003869;
}
.pg-bar-link:hover {
  color: #004785;
}
.pg-bar-link:focus {
  color: #004785;
}
#progress-bar a{
  color: #003869;
}
@media (max-width: 480px) {
  #progress-bar li {
    width: 20%;
  }
  #progress-bar li:before {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
    margin: 0 auto 10px auto;
    border-radius: 50%;
  }
  #progress-bar li:after {
    width: 100%;
    height: 5px;
    top: 12.5px;
    left: -50%;
  }
  #progress-bar a{
    font-size: 9px;
  }
  .pg-bar-link {
    font-size: 13px;
  }
}

@media (max-width: 415px) {
  .pg-bar-link {
    font-size: 10px;
  }
}

.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  height: 100%;
  background-color: #f6f6f8;
}
.footer {
  width: 100%;
  height: 100%;
}
.footer h4 {
  align-self: flex-start;
  text-align: start;
  font-style: normal;
  font-weight: 600;
}
.footer p {
  align-self: flex-start;
  text-align: start;
  font-size: 10px;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.logo {
  align-self: flex-start;
  padding-bottom: 15px;
  max-width: 36%;
}
.iconCont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.iconCont img {
  width: 25px;
  height: 25px;
}

.listCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 5px;
}
.list h4 {
  padding-bottom: 12px;
}
.list a {
  align-self: start;
  text-decoration: none;
}
.list h6 {
  text-align: start;
  align-self: start;
  font-size: 13px;
  font-weight: 500;
}
.list h6:hover {
  color: rgb(15, 15, 15);
}

.contactCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 15px;
}
.input {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}
.input input {
  width: 65%;
  background-color: #f6f6f8;
  margin: 0;
  border: none;
  border: 1px solid rgb(0, 0, 0);
  padding: 10px 15px 10px 15px;
  border-radius: 0px;
}
.input input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", "sans-serif";
  color: rgb(0, 0, 0);
}
.input input,
select,
textarea {
  color: #000000;
}
.input input:focus {
  outline: none;
}
.input button {
  width: 35%;
  background-color: #2d2d2d;
  padding: 10px 15px 10px 15px;
  border: none;
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat", "sans-serif";
  color: #ffffff;
}
.input button:hover {
  background-color: rgba(32, 32, 32, 0.897);
}
@media (max-width: 992px) {
  .listCol {
    justify-content: start;
    align-items: flex-start;
  }
  .main {
    padding: 40px 0px 40px 0px;
  }
  .logo {
    max-width: 180px;
  }
}
@media (max-width: 992px) {
  .logo {
    max-width: 155px;
  }
}

.filter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0px;
}
.search {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: rgb(244, 244, 244);
  padding: 15px;
}
.search input {
  width: 80%;
  background-color: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0;
}
.search input:focus {
  outline: none;
}
.search input::placeholder {
  font-size: 16px;
  font-family: "Montserrat", "sans-serif";
}
.search img {
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.gender {
  width: 200px;
  height: 100%;
  padding: 0px;
}
.gender select {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: "Montserrat", "sans-serif";
  color: rgb(50, 50, 50);
  margin: 0;
  border: 2px solid rgb(180, 180, 180);
  padding: 15px;
}
.gender select:focus {
  outline: none;
}
.gender select option {
  font-size: 16px;
  font-family: "Montserrat", "sans-serif";
}
.categories {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 40px;
  flex-direction: column;
}
.categories h5 {
  font-size: 22px;
  font-weight: 900;
}
.list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 20px;
  flex-direction: column;
}
.box {
  padding: 10px 0px 8px 0px;
  border-bottom: 1px solid rgb(158, 158, 158);
  width: 350px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
.box h6 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", "sans-serif";
  margin: 0;
}
.box span {
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat", "sans-serif";
}
.boxActive {
  border-bottom: 1px solid rgb(62, 62, 62);
}
.boxActive h6 {
  font-weight: 700;
  color: black;
}
.boxActive span {
  font-weight: 700;
  color: black;
}
.mobList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  display: none;
  margin-bottom: 45px;
  cursor: pointer;
}
.swiper {
  width: 100%;
  height: 70px;
}
.swiperSlide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .search {
    width: 245px;
  }
  .gender {
    width: 120px;
  }
  .box {
    width: 245px;
  }
}
@media (max-width: 992px) {
  .search {
    width: 170px;
  }
  .gender {
    width: 90px;
  }
  .categories {
    gap: 25px;
  }
  .box {
    padding: 6px 0px 0px 0px;
    width: 70%;
  }
  .box h6 {
    font-size: 11px;
  }
  .box span {
    font-size: 10px;
  }
  .mobList {
    display: block;
  }
  .list {
    display: none;
  }
}
@media (max-width: 767px) {
  .search {
    width: 130px;
    padding: 8px 12px 8px 12px;
  }
  .search input::placeholder {
    font-size: 12px;
  }
  .search img {
    height: 13px;
    width: 13px;
  }
  .gender {
    width: 75px;
  }
  .gender select {
    padding: 8px;
    font-size: 12px;
  }
  .gender select option {
    font-size: 12px;
  }
  .box {
    width: 130px;
  }
  .categories h5 {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .search {
    width: 110px;
  }
  .box {
    width: 95%;
  }
  .mobList{
    padding-bottom: 15px;
  }
  .categories {
    gap: 15px;
  }
  .categories h5 {
    font-size: 14px;
  }
  .list {
    gap: 15px;
  }
}

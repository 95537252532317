.Hero {
  width: 100%;
  height: 35vw;
  min-height: 28vh;
}
.Hero_Cont {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.009);
}
.Hero_Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.Hero_Content h1 {
  text-align: start;
  align-self: flex-start;
}
.Hero_Content p {
  text-align: start;
  align-self: flex-start;
  color: white;
}
@media (max-width: 767px) {
  .Hero_Content h4 {
    font-size: 8px;
    letter-spacing: 3px;
  }
}

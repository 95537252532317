.box .carousel-control-prev {
  display: none;
}
.box .carousel-control-next {
  display: none;
}
.carousel-caption {
  top: 50%;
  transform: translateY(-30%);
}
.carousel-caption h4 {
  padding-bottom: 40px;
}
.box h4 {
  color: white;
  letter-spacing: 5px;
}
@media (max-width: 1200px) {
  .carousel-caption h4 {
    padding-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .carousel-caption {
    transform: translateY(-50%);
  }
}
@media (max-width: 576px) {
  .carousel-caption h4 {
    padding-bottom: 15px;
  }
  .carousel-caption {
    transform: translateY(-70%);
  }
  .box h4 {
    font-size: 8px;
    letter-spacing: 2px;
  }
  .box img {
    min-height: 270px;
  }
}
@media (max-width: 380px) {
  .carousel-caption {
    transform: translateY(-80%);
  }
  .box img {
    min-height: 220px;
  }
}

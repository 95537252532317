.main {
  width: 100%;
  height: auto;
}
.boxCont {
  padding: 80px 0px 70px 0px;
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;
  cursor: pointer;
}
.main h2 {
  padding-bottom: 35px;
}
.box img {
  max-width: 120px;
}
.box p {
  padding-left: 18%;
  padding-right: 18%;
}
@media (max-width: 768px) {
  .main h2 {
    padding-bottom: 20px;
  }
  .boxCont {
    padding: 60px 0px 40px 0px;
  }
  .box {
    gap: 20px;
  }
  .box p {
    padding-left: 8%;
    padding-right: 8%;
  }
}

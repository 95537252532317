.reviews_swiper {
  width: 100%;
  min-height: 265px;
}
.reviews_swiper .swiper-pagination-bullet {
  padding: 5px;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 6px;
  color: #000000;
  opacity: 1;
  background: #dfdfdf;
}
.reviews_swiper .swiper-pagination-bullet-active {
  background: #9c9c9c;
  color: #ffffff;
}
@media (max-width: 992px) {
  .reviews_swiper {
    min-height: 350px;
  }
}
@media (max-width: 578px) {
  .reviews_swiper {
    min-height: 270px;
  }
}
@media (max-width: 425px) {
  .reviews_swiper {
    min-height: 300px;
  }
}

* {
    padding: 0;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .cards {
      background: #fff;
      height: auto;
      width: auto;
      overflow: hidden;
      box-shadow: 5px 8px 15px -10px rgba(0,0,0,0.25);
  }
  .card_image.loading {
      width: 100%;
      height: 180px;
  } 
   .card_title {
      padding: 8px;
      font-size: 22px;
      font-weight: 700;
  }
  .card_title.loading {
      width: 50%;
      height: 1rem;
      margin: 1rem;
      border-radius: 3px;
      position: relative;
  } 
  .card_description {
      padding: 8px;
      font-size: 16px;
  }
  .card_description.loading {
      height: 3rem;
      margin: 1rem;
      border-radius: 3px;
  }
  .loading {
      position: relative;
      background: #cccccc;
  }
  .loading:after {
      content: "";
       display: block; 
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100px);
      background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
      animation: loading 0.8s infinite;
  }
  @keyframes loading {
      100% {
          transform: translateX(100%);
      }
  }

.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.box img {
  width: 100%;
  border-radius: 5px;
}
.box p {
  align-self: flex-start;
  text-align: start;
}
.box h5 {
  align-self: flex-start;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  padding-top: 15px;
  color: black;
  margin: 0;
}
.box a {
  align-self: flex-start;
  text-align: start;
}
.box h6 {
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
.box h6:hover{
    font-weight: 600;
}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
}
.box img {
  width: 100%;
  align-self: center;
  max-width: 450px;
  border-radius: 5px;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.308);
  transition: 0.2s ease-in-out;
}
.box img:hover {
  box-shadow: 3px 5px 18px rgba(0, 0, 0, 0.498);
}
.box h4 {
  text-align: center;
  align-self: center;
}
